<template>
  <div class="Topstory-container">
    <div class="Topstory-mainColumn">
      <el-tabs v-model="activeName" @tab-click="changeItem">
        <el-tab-pane
          :label="sitem.SectionName"
          :name="sitem.SectionName"
          v-for="(sitem, sindex) in sectionList"
          :key="sindex"
          :sectionId="sitem.Id"
        >
          <div v-for="(item, index) in feedList" :key="index">
            <feed-item :feedList="item" :isDelete="false"></feed-item>
          </div>
          <div v-if="feedList.length <= 0">
            <no-data></no-data>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="关注" name="attention">
          <div v-for="(item,index) in followList" :key="index">
            <feed-item :feedList="item"></feed-item>
          </div>
        </el-tab-pane> -->
      </el-tabs>
      <el-button class="AskButton" @click="showAskModal" v-show="true">
        发帖
      </el-button>
      <div class="Loading" v-show="isLoad">拼命加载中</div>
      <div class="Loading" v-show="isEnd">我是有底线的</div>
    </div>
    <div class="GlobalSideBar">
      <global-side-bar></global-side-bar>
    </div>

    <!-- 查看 -->
    <AskModal
      v-if="showModal"
      :dialogVisible="showModal"
      :row="sectionList"
      :sectionId="sectionId"
      @close="showModal = false"
      @finishadd="finishadd"
    ></AskModal>

    <!-- 登录遮罩层 -->
    <modal :showModal="showLoginModal" @cancel="showLoginModal = false">
      <template v-slot:dialog>
        <div class="Login-wrap">
          <el-tabs>
            <el-tab-pane label="密码登陆" name="login">
              <div class="Sign-wrap">
                <input
                  class="SignInput"
                  type="text"
                  placeholder="用户名"
                  v-model="loginUser"
                />
              </div>
              <div class="Sign-wrap">
                <input
                  class="SignInput"
                  type="password"
                  placeholder="密码"
                  v-model="loginPass"
                />
              </div>
              <button class="SubmitButton" @click="login">登陆</button>
            </el-tab-pane>
          </el-tabs>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import { queryAllTopicList, querySectionList } from "@/api/bbs";
import FeedItem from "@/components/bbs/FeedItem.vue";
import GlobalSideBar from "@/components/bbs/GlobalSideBar.vue";
import Modal from "@/components/bbs/Modal.vue";
import AskModal from "@/components/bbs/AskModal.vue";
import noData from "@/components/noData";
// import { mapGetters } from "vuex";

let Load;
export default {
  name: "index",
  components: {
    FeedItem,
    GlobalSideBar,
    Modal,
    AskModal,
    noData
  },
  computed: {
    //监听词条
    // ...mapGetters(["isAdd", "searchList"])
  },
  provide () {
    return {
      finishDelete: ""
    }
  },
  watch: {
    isAdd: function (newValue) {
      // this.getContent();
      this.postQueryAllTopicList();
    },
    searchList: function (newValue) {
      this.feedList = newValue;
      console.log("更新列表");
    },
  },
  data () {
    return {
      feedList: [],
      activeName: "",
      followList: [],
      isLoad: false,
      recPage: 1, //推荐页面page
      // attPage: 1, //关注页面page
      pageSize: 10,
      isEnd: false,
      user: {},
      sectionList: [],
      sectionId: 1,
      showLoginModal: false,
      showModal: false,
      disabled: true,
      askDetail: "", //提问细节
      askTip: "",
      askTitle: "", //提问问题
      loginUser: "", //登录用户
      loginPass: "", //注册密码
    };
  },
  mounted () {
    // this.getContent();
    if (localStorage.getItem("userInfo") != null) {
      this.user = JSON.parse(localStorage.getItem("userInfo"));
    } else {
      this.user.Id = 0;
    }
    this.postQuerySectionList();
    this.postQueryAllTopicList();
    window.addEventListener('scroll', this.listenScroll);
  },
  methods: {
    showLoginModalFn () {
      this.showLoginModal = true;
    },
    showAskModal () {
      if (this.user.Id == 0) {
        this.$message.error("请先登录");
        return false;
      }
      this.showModal = true;
      console.log(this.showModal);
    },
    finishadd () {
      this.feedList = [];
      this.recPage = 1;
      this.postQueryAllTopicList();
    },
    //登录
    login () {
      let params = {
        username: this.loginUser,
        password: this.loginPass,
        rememberme: this.checked,
      };
      this.axios.post("/login", params).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: "登录成功",
            type: "success",
          });
          localStorage.setItem("user", JSON.stringify(res.data.user));
          this.user = res.data.user;
          this.showLoginModal = false;
          this.isLogin = true;
        }
      });
    },
    //获取论坛版块
    async postQuerySectionList () {
      // 点赞/取消点赞
      let parm = {};
      parm.PageSize = 10;
      parm.PageIndex = 1;
      parm.Status = 1;
      const res = await querySectionList(parm);
      this.sectionList = res.data;
      this.activeName = this.sectionList[0].SectionName;
    },
    // 列表数据
    async postQueryAllTopicList () {
      let parm = {};
      parm.pageIndex = this.recPage;
      parm.pageSize = this.pageSize;
      parm.IsAll = true;
      parm.UserId = this.user.Id;
      parm.sectionId = this.sectionId;
      const res = await queryAllTopicList(parm);
      this.feedList = this.feedList.concat(res.data);
      this.$store.dispatch("setIsAdd", false);
      this.isLoad = false;
      if (res.data.length == 0) {
        this.isEnd = true;
      }
    },
    //滚动
    listenScroll () {
      let that = this;
      let ele = document.documentElement;
      let scr = ele.scrollTop; // 向上滚动的那一部分高度
      let clientHeight = ele.clientHeight; // 屏幕高度也就是当前设备静态下你所看到的视觉高度
      let scrHeight = ele.scrollHeight; // 整个网页的实际高度，兼容Pc端
      if (scr + clientHeight + 5 >= scrHeight) {
        // console.log(that.isEnd);
        // if (that.isLoad == false||this.isEnd == false) {
        if (that.isEnd == false) {
          // that.isLoad = true;
          that.recPage++;
          that.postQueryAllTopicList();
        }
      }
    },
    changeItem: function (tab, event) {
      this.sectionId = tab.$attrs.sectionId;
      this.feedList = [];
      this.recPage = 1;
      this.postQueryAllTopicList();
    },
    //发表问题
    publishQues () {
      let params = {
        title: this.askTitle,
        content: this.askDetail,
      };
      this.axios.post("/question/add", params).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: "提问成功",
            type: "success",
          });
          this.showModal = false;
          this.askTitle = "";
          this.askDetail = "";
          this.$store.dispatch("setIsAdd", true);
        }
      });
    },
    //监听标题文字数量
    listenChange () {
      if (this.askTitle.length < 4) {
        this.askTip = "至少输入4个字";
        this.disabled = true;
      } else {
        this.askTip = "";
        this.disabled = false;
      }
    },
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.listenScroll);
  },
};
</script>
<style lang="scss" >
@import "../../assets/css/config";
.Topstory-container {
  display: flex;
  width: 1200px;
  padding: 0 16px;
  margin: 10px auto;
  height: 100%;
}
.Topstory-mainColumn {
  position: relative;
  width: 854px;
  height: 100%;
  .el-tabs__header {
    background: #ffffff;
    padding: 10px 20px;
    margin: 0;
    border-bottom: 1px solid #ebebeb;
  }
  .el-tabs__item {
    font-size: 16px;
  }
  .el-tabs__item.is-active {
    color: $mainColor;
  }
  .el-tabs__nav-wrap::after {
    background: transparent;
  }
  .el-tabs__active-bar {
    height: 0;
  }
}
.GlobalSideBar {
  margin-left: 10px;
  flex: 1;
}
.Loading {
  height: 50px;
  text-align: center;
  color: $mainColor;
}
.AskButton {
  color: #fff;
  background-color: #0084ff;
  width: 90px;
  padding: 8px 16px;
  font-size: 14px;
  margin-top: 10px;
  align-self: flex-end;
  &:disabled {
    opacity: 0.5;
  }
  position: absolute;
  top: 4px;
  right: 2.6%;
  border-radius: 999px;
  width: 70px;
}
</style>